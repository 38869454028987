<template>
	<div id="main">
		<img src="~@/assets/img/ticket/index.png" alt="">
	</div>
</template>

<script>
  export default {
    name: "TicketIndex",
  }
</script>

<style scoped>
	#main{
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	img{
		width: 602px;
		height: 300px;
	}
</style>